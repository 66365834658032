import React from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
    scorePenalty1: number;
    scorePenalty2: number;
}

export function PenaltyScore({ scorePenalty1, scorePenalty2 }: Props): JSX.Element {
    return (
        <span>
            (<FormattedMessage id='v3_gamesandstandings.a_p' defaultMessage='a.P.' /> {`${scorePenalty1}:${scorePenalty2}`})
        </span>
    )
}