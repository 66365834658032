import { IGame } from '@/shared/models/Game';


export function getGameSectionQueryString(sectionId: number){
    return {
        direction: `games-list-${sectionId}-direction`,
        page: `games-list-${sectionId}-page`,
    }
}

export function isValidGameSectionQueryString({ page, direction}: { page: string, direction: string }) {
    const pageNumber = Number(page)
    if(!['future', 'past'].includes(direction)) {
        return false
    }
    if(!isNaN(pageNumber) &&  parseInt(page) == pageNumber &&  !isNaN(parseInt(page, 10))) {
        return pageNumber > 0
    }
    return true;
}


export function getClosestGameIndex(games: IGame[]) {
    let index = 0
    const today = new Date().getTime()
    let closest
    games.forEach((e, i) => {
        const currentDiff = Math.abs(today - new Date(e.start_date).getTime())
        if(i === 0) {
            closest = currentDiff
        } else if (closest > currentDiff) {
            index = i
            closest = currentDiff
        }
    })
    return index
}