import { defineMessages } from 'react-intl'

export const LEAGUE_KEY = 'league'
export const FRIENDLY_GAMES_ID = Number.MAX_SAFE_INTEGER

export const gamesMessages = defineMessages({
    'to_be_announced': {
        id: 'v3_steps.to_be_announced',	        
        // defaultMessage: 'TBA'
        defaultMessage: '###' //TBA
    }
})