import MenuItem from '@material-ui/core/MenuItem';
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import { ReactNode } from 'react';

type IValidatedSelectInputProps = TextFieldProps & {
  options: { label: ReactNode, value: any }[]
}

export default function ValidatedSelectInput(props: IValidatedSelectInputProps) {
  const { options, ...restProps} = props
  return (
      <TextField {...restProps} select fullWidth variant='outlined'>
            {options.map(option => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))}
      </TextField>
  )
}