import { ISeason } from '@/shared/models/Season';
import { sortSeasons } from '@/shared/utils/seasons';
import { request } from './api';

export async function getSeasonsByType({ clubPrefix, seasonTypeId}: { clubPrefix: string, seasonTypeId: number }): Promise<ISeason[]> {
    if(!seasonTypeId) {
        return []
    }
    const seasons = await request({
        endpoint: `/seasons`,
        clubPrefix,
        query: {
            season_type: seasonTypeId
        }
    })
    return sortSeasons(seasons)
}

export async function getLeagueSeasonsWithStats({ clubPrefix, leagueId, groupId }: { clubPrefix: string, leagueId: number, groupId?: number }): Promise<ISeason[]> {
    let seasons = []
    if(groupId) {
        seasons = await request({
            endpoint: `/competitions/${leagueId}/teams/${groupId}/seasonshavingplayerstats`,
            clubPrefix,
        })
    } else {
        seasons = await request({
            endpoint: `/competitions/${leagueId}/seasonshavingplayerstats`,
            clubPrefix,
        })
    }
    return sortSeasons(seasons).filter(Boolean)
}

export async function getLeagueSeasonsWithStandings({ clubPrefix, leagueId, groupId }: { clubPrefix: string, leagueId: number, groupId?: number }): Promise<ISeason[]> {
    let seasons = []
    if(groupId) {
        seasons = await request({
            endpoint: `/competitions/${leagueId}/teams/${groupId}/seasonshavingstandings`,
            clubPrefix,
        })
    } else {
        seasons = await request({
            endpoint: `/competitions/${leagueId}/seasonshavingstandings`,
            clubPrefix,
        })
    }
    return sortSeasons(seasons).filter(Boolean)
}

export async function getLeagueSeasonsWithGames({ clubPrefix, leagueId, groupId }: { clubPrefix: string, leagueId: number, groupId?: number }): Promise<ISeason[]> {
    let seasons = []
    if(groupId) {
        seasons = await request({
            endpoint: `/competitions/${leagueId}/teams/${groupId}/seasonshavinggames`,
            clubPrefix,
        })
    } else {
        seasons = await request({
            endpoint: `/competitions/${leagueId}/seasonshavinggames`,
            clubPrefix,
        })
    }
    return sortSeasons(seasons).filter(Boolean)
}


export async function getRosterSeasons({ groupId, clubPrefix }: { clubPrefix: string, groupId: number }): Promise<ISeason[]> {
    const seasons = await request({
        endpoint: `/groups/${groupId}/roster/seasons`,
        clubPrefix,
    })
    return sortSeasons(seasons).filter(Boolean)
}