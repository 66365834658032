import { ITheme } from '@/shared/styles/MuiTheme';
import { makeStyles } from '@material-ui/core';
import { GameCardLayoutType } from './types';

interface IStyleProps {
    hasLeagueButton?: boolean;
    gameCardType?: GameCardLayoutType
}

export const useGameCardStyles = makeStyles((theme: ITheme) => ({
    team: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            paddingTop: ({ hasLeagueButton }: IStyleProps) => (hasLeagueButton ? theme.spacing(6) : theme.spacing(4))
        },
        padding: theme.spacing(4),
        display: 'flex',
        textAlign: 'center',
        flexDirection: (props) => props.gameCardType === GameCardLayoutType.FLF ? 'column-reverse' : undefined
    },
    teamLeft: {
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0, 0, 2)
        }
    },
    teamRight: {
        marginRight: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(0, 0, 2)
        }
    },
    noWrap: {
        whiteSpace: 'nowrap'
    },
    slugContainer: {
        position: 'relative',
        '& > div': {
            [theme.breakpoints.down('sm')]: {
                position: 'absolute'
            }
        }
    },
    navigationButton: {
        '& p': {
            fontWeight: 700,
        }
    },
    viewEvent: {
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(0)
        }
    },
    teamImage: {
        height: '72px',
        width: '72px',
        maxHeight: '72px',
        maxWidth: '72px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
            height: '48px',
            width: '48px',
            maxHeight: '48px',
            maxWidth: '48px',
        }
    },
    eventDetails: {
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(2),
            borderRight: `1px solid ${theme.props.sectionMode.card}`
        }
    },
    customDivider: {
        '& hr': {
            backgroundColor: theme.props.sectionMode.text_background
        }
    },
    customEventDetails: {
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(2),
            borderRight: `1px solid ${theme.props.sectionMode.text_background}`
        }
    },
    venueDesctipntion: {
        [theme.breakpoints.up('md')]: {
            textAlign: 'right',
        }
    },
    adRoot: {
        width: '100%',
        display: 'flex',
        margin: '0 0 24px',
        justifyContent: 'center',
    },
    teamWrapper: {
        padding: '0 16px'
    },
    teamPadding: {
        paddingTop: 20
    },
    teamScoreContainer: {
        display: 'flex',
        width: 'inherit',
        alignItems: 'flex-start'
    },
    cancelledText: {
        textDecoration: 'line-through',
        color: theme.props.sectionMode.text_background
    },
    liveStreamButton: {
        marginLeft: 10,
        position: 'relative',
        left: 30,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    liveStreamButtonMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            position: 'relative',
            top: 10
        },
    },
    liveStreamButtonMobileFLF: {
        display: 'block',
        position: 'relative',
        top: 3,
        marginBottom: 6,
        marginLeft: 6
    },
    teamInner: {
        display: 'flex',
        alignItems:'center',
        justifyContent: 'flex-end',
        width: 'inherit',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse'
        }
    },
    teamInnerReverse: {
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse'
        }
    },
    teamInnerParapraph: {
        display:'flex',
        alignItems:'center',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse'
        }
    },
    teamInnerParapraphSecond: {
        display:'flex',
        alignItems:'center',
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse'
        }
    },
    teamInnerParapraphFLF: {
        display:'flex',
        alignItems:'center',
        flexDirection: 'column-reverse'
    },
    teamInnerParapraphSecondFLF: {
        display:'flex',
        alignItems:'center',
        flexDirection: 'column-reverse'
    }
}))
