import { ICategory } from '@/shared/models/Category';
import { ICountry } from '@/shared/models/Country';
import { IGame } from '@/shared/models/Game';
import { IGroup } from '@/shared/models/Group';
import { ILeague } from '@/shared/models/League';
import { IMessages } from '@/shared/models/Messages';
import { ISeason } from '@/shared/models/Season';
import { ISection } from '@/shared/models/Section';
import { ILoadedGamesMap } from './hooks/useGameLoader';

export interface IGamesSectionData {
    seasons?: ISeason[],
    season?: ISeason,
    leagues?: ILeague[];
    currentWeekIndex?: number;
    leagueGamesIndex?: number;
    chunkedGames?: { id: number }[][],
    group?: IGroup,
    league?: ILeague,
    games?: IGame[],
    categories?: ICategory[],
    countries?: ICountry[],
    placeholders?: {
        [key in string]?: IMessages
    },
    initialLoadedGames?: ILoadedGamesMap,
    gameIds?: {id: number}[],
    closestGameIndex?: number,
    section?: ISection
}

export interface IGamesSectionContent {
    team: {
        id: number;
    };
    league: {
        id: number
    },
}

export enum GameCardLayoutType {
    DEFAULT = 'default',
    FLF = 'flf'
}