import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Headline2 } from '@/shared/components/Typography'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { DEFAULT_TEAM_LOGO } from '@/shared/components/Avatar/Avatar';
import { Img } from '@/shared/components/Img/Img'
import TextButton from '@/shared/components/Button/TextButton';
import { ISectionComponentProps } from 'components/Sections/utils'
import { useIntl } from 'react-intl'
import { sectionMessages } from 'components/Sections/messages'
import SecondaryButton from '@/shared/components/Button/SecondaryButton';
import { useState } from 'react'
import { useSystemPagePath } from 'utils/hooks'
import { WebsitePageType } from '@/shared/models/Website'
import { getGame } from 'services/games'
import Loader from '@/shared/components/Loader/Loader';
import { useRouter } from 'next/router'
import { getGameSectionQueryString } from '../utils'
import LeagueGamesPlaceholder from '../LeagueGamesPlaceholder'
import GameCard from '../GameCard'
import { gameSorter } from '@/shared/utils/helpers'
import { useClub } from 'contexts/club'
import { useGameCardStyles } from '../styles'
import { GameCardLayoutType } from '../types'
import { LEAGUE_KEY } from '../const'


interface ILeagueGamesProps extends ISectionComponentProps {
    gameCardType?: GameCardLayoutType
    hideHeader?: boolean
    hideViewMore?: boolean
}
export default function LeagueGames(props: ILeagueGamesProps) {
    const { data, section, gameCardType, hideHeader, hideViewMore } = props
    const { league, games, categories, chunkedGames, leagueGamesIndex, currentWeekIndex } = data
    const intl = useIntl()
    const [currentIndex, setCurrentIndex] = useState(leagueGamesIndex)
    const [isFetching, setIsFetching] = useState(false)
    const [currentList, setCurrentList] = useState(games)
    const viewMoreLink = `${useSystemPagePath(WebsitePageType.RESULTS)}/leagues/${league?.id}`
    const router = useRouter()
    const isInitialEmpty = games.length == 0
    const isEmpty = chunkedGames.length === 1 && currentList.length === 0;
    const { prefix } = useClub()
    const classes = useGameCardStyles({ hasLeagueButton: false })

    return (
        <Box>
            {!hideHeader && (
                <Box pb={4} display='flex' alignItems='center'>
                    <Box mr={2}>
                        <Img mods='w48' src={league?.picture ?? DEFAULT_TEAM_LOGO} failoverImage={DEFAULT_TEAM_LOGO}/>
                    </Box>
                    <Headline2>{league?.name}</Headline2>
                </Box>
            )}
            <Box pb={4}>
                <Grid container>
                    <Grid item xs={6} container justifyContent='flex-start' className={classes.navigationButton}>
                        {!(currentIndex === 0) && !isInitialEmpty && (
                            <TextButton href={`?${new URLSearchParams(getRouteQuery(currentIndex-1) as string | string[][] | Record<string, string> | URLSearchParams)}`} onClick={(e) => {
                                e.preventDefault()
                                onNavigate('prev')
                            }} startIcon={<ArrowBackIosIcon />}>{intl.formatMessage(sectionMessages['games.previous_week'])}</TextButton>
                        )}
                    </Grid>
                    <Grid item xs={6} container justifyContent='flex-end' className={classes.navigationButton}>
                        {!(currentIndex >= (chunkedGames.length - 1)) && !isInitialEmpty && (
                            <TextButton href={`?${new URLSearchParams(getRouteQuery(currentIndex+1) as string | string[][] | Record<string, string> | URLSearchParams)}`} onClick={(e) => {
                                e.preventDefault()
                                onNavigate('next')
                            }} endIcon={<ArrowForwardIosIcon />}>{intl.formatMessage(sectionMessages['games.next_week'])}</TextButton>
                        )}
                    </Grid>
                </Grid>
            </Box>
            {isFetching && (
                <Loader/>
            )}
            {!isFetching && currentList.length === 0 && (
                <Box pb={4}>
                    <LeagueGamesPlaceholder title={intl.formatMessage(sectionMessages['games.empty'])}/>
                </Box>
            )}
            {!isFetching && currentList.map(game => (
                <GameCard gameCardType={gameCardType} key={game.id} game={game} categories={categories} />
            ))}
            {!isEmpty && !isFetching && chunkedGames.length > 0 && !hideViewMore && (
                <Box display='flex' justifyContent='center'>
                    <SecondaryButton applyBackgroundColor href={viewMoreLink}>{intl.formatMessage(sectionMessages['website.view_more'])}</SecondaryButton>
                </Box>
            )}
        </Box>
    )

    function onNavigate(direction: 'next' | 'prev') {
        let newIndex = currentIndex
        if(direction === 'next') {
            newIndex = newIndex + 1
        } else {
            newIndex = newIndex - 1
        }
        setCurrentIndex(newIndex)
        fetchNewList(newIndex)
        changeRoute(newIndex)
    }

    function getRouteQuery(newIndex: number, query = {}) {
        const qs = getGameSectionQueryString(section.id)
        query[LEAGUE_KEY] = league.id
        if(newIndex > currentWeekIndex) {
            query[qs.direction] = 'future'
            query[qs.page] = `${(newIndex - currentWeekIndex) + 1}`
        } else if(newIndex < currentWeekIndex) {
            query[qs.direction] = 'past'
            query[qs.page] = `${newIndex + 1}`
        } else {
            delete router.query[qs.direction]
            delete router.query[qs.page]
        }
        return query
    }

    function changeRoute(newIndex: number) {
        getRouteQuery(newIndex, router.query)
        router.push(router, undefined, { shallow: true })
    }

    function fetchNewList(index: number) {
        setIsFetching(true)
        Promise.all(chunkedGames[index].map(e => getGame(e.id, { clubPrefix: prefix })))
            .then(newGames => {
                newGames.sort(gameSorter)
                setCurrentList(newGames)
            }).finally(() => {
                setIsFetching(false)
            })
    }
}