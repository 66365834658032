import { ISeason } from '@/shared/models/Season'


export const SEASON_TYPE_ID_FOR_DOUBLE_YEAR = 1
export const SEASON_TYPE_ID_FOR_SINGLE_YEAR = 2
export const DOUBLE_YEAR_SWITCH_MONTH = 6


export function getSeasonTypeFromSeasons(seasons: ISeason[]): number {
    return seasons[0].name.length === 4 ? SEASON_TYPE_ID_FOR_SINGLE_YEAR : SEASON_TYPE_ID_FOR_DOUBLE_YEAR
}

export const sortSeasons = (seasons: ISeason[]) => {
    // sort by year first
    seasons.sort((a, b) => (parseInt(a.name.substr(0, 4), 10) < parseInt(b.name.substr(0, 4), 10)) ? 1 : -1)
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const s = markCurrentNextPrevSeason({ seasons })
    for (let i = 0; i < s.length; i++) {
        const currentSeason = s[i]
        if (currentSeason.afterNext) {
            // prepend 5 seasons
            s.unshift(
                s[i],
                s[i + 1],
                s[i + 2],
                s[i + 3],
                s[i + 4]
            )
            // remove them
            s.splice(i + 5, 5)
            break
        } else if (currentSeason.next) {
            // in case we don't have 2 seasons in the future (should not happen)
            // prepend 4 seasons
            s.unshift(
                s[i],
                s[i + 1],
                s[i + 2],
                s[i + 3]
            )
            // remove them
            s.splice(i + 4, 4)
            break
        } else if (currentSeason.current) {
            // in case we don't have any season in the future (should not happen)
            // prepend 2 seasons
            s.unshift(
                s[i],
                s[i + 1],
                s[i + 2]
            )
            // remove them
            s.splice(i + 3, 3)
            break
        }
    }
    return s
}

export const markCurrentNextPrevSeason = ({
    seasons,
    currentYear = new Date().getFullYear(),
    currentMonth = new Date().getMonth()
}) => {

    if (!seasons) {
        return seasons
    }

    if (!seasons || !seasons.length) {
        return seasons
    }

    const type = getSeasonTypeFromSeasons(seasons)

    switch (type) {
        case SEASON_TYPE_ID_FOR_SINGLE_YEAR: {
            seasons.map(season => {
                const { name } = season
                const year = parseInt(name, 10)
                if (year === currentYear) {
                    season.current = true
                } else if (year === currentYear + 2) {
                    season.afterNext = true
                } else if (year === currentYear + 1) {
                    season.next = true
                } else if (year === currentYear - 1) {
                    season.previous = true
                } else if (year === currentYear - 2) {
                    season.beforePrevious = true
                } else if (year === currentYear - 3) {
                    season.beforeBeforePrevious = true
                }
                season.type = SEASON_TYPE_ID_FOR_SINGLE_YEAR

                return season
            })
            break
        }
        case SEASON_TYPE_ID_FOR_DOUBLE_YEAR: {
            let seasonBeginning = true
            if (currentMonth < DOUBLE_YEAR_SWITCH_MONTH) {
                // we are still in the final phase of a 2-year season
                seasonBeginning = false
            }
            seasons.map(season => {
                const { name } = season
                const year = seasonBeginning ?
                    parseInt(name.substr(0, 4), 10)
                    : parseInt(name.substr(5), 10)

                if (year === currentYear) {
                    season.current = true
                } else if (year === currentYear + 1) {
                    season.next = true
                } else if (year === currentYear + 2) {
                    season.afterNext = true
                } else if (year === currentYear - 1) {
                    season.previous = true
                } else if (year === currentYear - 2) {
                    season.beforePrevious = true
                } else if (year === currentYear - 3) {
                    season.beforeBeforePrevious = true
                }
                season.type = SEASON_TYPE_ID_FOR_DOUBLE_YEAR

                return season
            })
            break
        }
        default:
            break
    }
    return seasons
}