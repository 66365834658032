import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { FormattedMessage } from 'react-intl'
import Card from '@/shared/components/Card';
import { IGame } from '@/shared/models/Game';
import { IGroup } from '@/shared/models/Group';
import { Headline3, Paragraph2 } from '@/shared/components/Typography'
import { generateTagLabel } from '@/shared/utils/generateTeamName';
import { ICategory } from '@/shared/models/Category';
import { useTranslations } from 'contexts/translations';
import moment from 'moment';
import { Img } from '@/shared/components/Img/Img'
import { checkFutureGame, displayGameScore, displayLiveStreamButton, displayReplayButton, hasScore } from '@/shared/utils/helpers';
import { DEFAULT_TEAM_LOGO } from '@/shared/components/Avatar/Avatar';
import { useGameCardStyles } from './styles';
import { Slug } from '@/shared/components/Slug';
import { useIntl } from 'react-intl';
import { sectionMessages } from '../messages';
import { ILeague } from '@/shared/models/League';
import { useSystemPagePath } from 'utils/hooks';
import { WebsitePageType } from '@/shared/models/Website';
import ElementLink from '@/shared/components/ElementLink/ElementLink';
import { GameCardLayoutType } from './types';
import LiveStreamButton from '@/shared/components/Button/LiveStreamButton';
import { gamesMessages } from './const';
import { PenaltyScore } from '@/shared/components/PenaltyScore/PenaltyScore';

interface IGameCardProps {
    game: IGame
    categories: ICategory[],
    showLeague?: boolean,
    selectedLeague?: ILeague
    gameCardType?: GameCardLayoutType
}
const iconPath = `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}`
export default function GameCard(props: IGameCardProps) {
    const { game, categories, showLeague, selectedLeague, gameCardType } = props
    const { translations: { messages }, simpleDateFormatter } = useTranslations()
    const intl = useIntl()
    const gameBaseLink = useSystemPagePath(WebsitePageType.GAME)
    const displayedLeague = game?.competition || selectedLeague
    const classes = useGameCardStyles({ hasLeagueButton: showLeague, gameCardType })
    const isFlf = gameCardType === GameCardLayoutType.FLF
    const hasGameScore = hasScore(game)
    const showLiveStreamButton = displayLiveStreamButton(game)
    const showReplayButton = displayReplayButton(game)

    const gameDescription = !game?.team1 && !game?.team2 && game?.information ? (
        <Box display='flex' justifyContent='center' className={classes.slugContainer}>
            <Paragraph2>{game.information}</Paragraph2>
        </Box>
    ) : null

    return (
        <ElementLink link={`${gameBaseLink}/${game.id}`}>
            <Box pb={4}>
                <Card>
                    <Box px={4}>
                        {showLeague && (
                            <Box display='flex' justifyContent='center' className={classes.slugContainer}>
                                <Slug imgSrc={`${iconPath}/websites/Match.svg`} text={<Paragraph2 isAccent><b>{displayedLeague?.name || intl.formatMessage(sectionMessages['games.friendly_game'])}</b></Paragraph2>}/>
                            </Box>
                        )}
                        {gameDescription}
                        <Grid container>
                            <Grid item xs={5} container alignItems='center' className={classes.team} justifyContent='flex-end'>
                                {renderTeam(game.team1, classes.teamLeft)}
                            </Grid>
                            <Grid item xs={2} container alignItems='center' justifyContent='center'>
                                {renderResults(game)}
                            </Grid>
                            <Grid item xs={5} container alignItems='center' className={classes.team} justifyContent='flex-end' direction='row-reverse'>
                                {renderTeam(game.team2, classes.teamRight, true, showLiveStreamButton || showReplayButton)}
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box>
        </ElementLink>
    )

    function renderTeam(team: IGroup, className: string, isSecond?: boolean, displayStreamButton?: boolean) {
        const teamName = isFlf ? team?.name : generateTagLabel(team, null, categories, {
            useTranslationsInsteadOfIntl: true,
            translations: messages
        })
        const paragraphClass = isFlf ? classes.teamInnerParapraphFLF : classes.teamInnerParapraph
        const paragraphClassSecond = isFlf ? classes.teamInnerParapraphSecondFLF : classes.teamInnerParapraphSecond
        return (
            <div className={`${classes.teamInner} ${isSecond && classes.teamInnerReverse}`}>
                {(displayStreamButton && !isFlf) && (
                    <div className={classes.liveStreamButton}>
                        <LiveStreamButton streamLink={game.stream_link} showLiveStream={showLiveStreamButton} />
                    </div>
                )}
                <div className={`${classes.teamInner} ${isSecond ? paragraphClassSecond : paragraphClass}`}>
                    <div >
                        <div style={{width:'100%'}}>
                            <Paragraph2>
                                {isFlf ? teamName  || <FormattedMessage {...gamesMessages.to_be_announced} /> : (
                                    <b>
                                        {teamName || <FormattedMessage {...gamesMessages.to_be_announced} />}
                                    </b>
                                )} 
                            </Paragraph2>
                        </div>
                    </div>
                    <div>
                        <Img
                            mods={isFlf ? 'w24': 'w48'}
                            className={className}
                            src={team?.pic || DEFAULT_TEAM_LOGO}
                            failoverImage={DEFAULT_TEAM_LOGO}
                        />
                    </div>
                </div>
            </div>
        )
    }

    function renderResults(game: IGame) {
        const momentDate = moment(game.start_date)
        const isFutureGame = checkFutureGame(game)
        return (
            <Box textAlign='center' className={classes.noWrap}>
                <Headline3 className={game.cancelled && hasGameScore && classes.cancelledText}>{isFutureGame ? `${momentDate.format('HH')}h${momentDate.format('mm')}` : displayGameScore(game)}</Headline3>
                {(typeof game.score1_penalties === 'number' && typeof game.score2_penalties === 'number') && (
                    <Paragraph2>
                        <PenaltyScore scorePenalty1={game.score1_penalties} scorePenalty2={game.score2_penalties} />
                    </Paragraph2>
                )}
                <Paragraph2>{game.cancelled ? intl.formatMessage(sectionMessages['games.cancelled']) : simpleDateFormatter(game.start_date, 'DD.MM.YYYY')}</Paragraph2>
                {(showLiveStreamButton || showReplayButton) && (
                    <div className={!isFlf ? classes.liveStreamButtonMobile : classes.liveStreamButtonMobileFLF}>
                        <LiveStreamButton streamLink={game.stream_link} showLiveStream={showLiveStreamButton} />
                    </div>
                )}
            </Box>
        )
    }
}
