import React from 'react'
import { useIntl } from 'react-intl'

import { eventPageMessages } from 'components/Event/consts'
import { useStyles } from './styles'
import { Paragraph1 } from '../Typography'

interface IProps {
    streamLink: string;
    showLiveStream: boolean;
}

export default function LiveStreamButton({ streamLink, showLiveStream }: IProps): React.ReactElement {
    const classes = useStyles()
    const intl = useIntl()
    function handleClick(): void {
        window.open(streamLink, '_blank')
    }
    return (
        <button
            className={classes.liveStreamButton}
            onClick={e => {
                e.preventDefault()
                handleClick()
            }}
        >
            {showLiveStream ? (
                <>
                    <div className={classes.liveStreamButtonCircle}></div>
                    <Paragraph1 className={classes.liveStreamButtonText}>
                        {intl.formatMessage(eventPageMessages['event.page.live_stream'])}
                    </Paragraph1>
                </>
            ) : (
                <>
                    <div className={classes.playButton}></div>
                    <Paragraph1 className={classes.liveStreamButtonText}>
                        {intl.formatMessage(eventPageMessages['event.page.replay'])}
                    </Paragraph1>
                </>
            )}
        </button>
    )
}