import { range } from '@/shared/utils/range'
import { useTablePlaceholderStyles } from './styles'

interface ITablePlaceholderProps {
    rows: number
}

function TablePlaceholder(props: ITablePlaceholderProps) {
    const { rows } = props
    const classes = useTablePlaceholderStyles()
    return (
        <div className={classes.container}>
            {range(rows).map(e => (
                <div key={e} className={classes.row}/>
            ))}
        </div>
    )
}

export default TablePlaceholder