import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Card from '@/shared/components/Card';
import { Headline3 } from '@/shared/components/Typography';

type ILeagueGamesPlaceholderProps = {
    title: string
}
export default function LeagueGamesPlaceholder(props: ILeagueGamesPlaceholderProps) {
    return (
        <Box>
            <Box pb={4} display='flex'>
                <Headline3>{props.title}</Headline3>
            </Box>
            <Box display='flex' alignItems='center'>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Card>
                            <Box height={90}>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <Box height={90}>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <Box height={90}>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}